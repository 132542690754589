import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import escudocole from './escudocole.jpeg';

class Navbar extends Component{
    render(){
        return(
            <header>
	<div class="container">
		<div class="header d-lg-flex justify-content-between align-items-center">
			<div class="header-agile">
				<h1>
					<Link to='/' class="navbar-brand logo">

					{/* I.E. Quichaya */}
					
					<img src="assets/images/escudopagina.png" alt="" class="img-fluid" />

						{/* <span class="fa fa-scissors" aria-hidden="true" ></span> */} 	
						{/* <span class="fa fa-user-secret" aria-hidden="true" ></span> */}

					</Link>
				</h1>
			</div>
			<div class="nav_w3ls">
				<nav>
					<label for="drop" class="toggle mt-lg-0 mt-1"><span class="fa fa-bars" aria-hidden="true"></span></label>
					<input type="checkbox" id="drop" />
						<ul class="menu">
							<li class="mr-lg-3 mr-2 active"><Link to='/'>Inicio</Link></li>
							{/* <li class="mr-lg-3 mr-2"><Link to='/institucional'>Institucional</Link></li> */}

							<li class="mr-lg-3 mr-2 p-0">
							{/* <!-- First Tier Drop Down --> */}
							<label for="drop-2" class="toggle">Institucional <span class="fa fa-angle-down" aria-hidden="true"></span> </label>
							<a href="#">Institucional <span class="fa fa-angle-down" aria-hidden="true"></span></a>
							<input type="checkbox" id="drop-2"/>
							<ul class="inner-dropdown">
								<li><Link to='/institucional'>Quienes somos</Link></li>
								<li><Link to='/simbolos'>Politico</Link></li> {/* nueva linea */}
								<li><Link to='/simbolos'>Cabildo escolar</Link></li> {/* nueva linea */}
								<li><Link to='/simbolos'>Finca </Link></li> {/* nueva linea */}
								<li><Link to='/simbolos'>Consejo directivo</Link></li> {/* nueva linea */}
								<li><Link to='/simbolos'>Nuestros simbolos</Link></li> {/* nueva linea */}
							</ul>
							</li>

							<li class="mr-lg-3 mr-2 p-0">
							{/* <!-- First Tier Drop Down --> */}
							<label for="drop-3" class="toggle">Sedes <span class="fa fa-angle-down" aria-hidden="true"></span> </label>
							<a href="#">Sedes <span class="fa fa-angle-down" aria-hidden="true"></span></a>
							<input type="checkbox" id="drop-3"/>
							<ul class="inner-dropdown">
								<li><Link to='/altamira'>Escuela Altamira</Link></li>
								<li><Link to='/cabuyal'>Escuela Cabuyal</Link></li>
								<li><Link to='/palmar'>Escuela Palmar</Link></li> {/* nueva linea */}
								<li><Link to='/dantas'>Escuela Dantas</Link></li> {/* nueva linea */}
								<li><Link to='/golondrinas'>Escuela Golondrinas</Link></li> {/* nueva linea */}
								<li><Link to='/chuluambo'>Escuela Chuluambo</Link></li> {/* nueva linea */}
							</ul>
							</li>

							<li class="mr-lg-3 mr-2"><Link to='/estudiantes'>Estudiantes</Link></li> {/* nueva linea */}
							<li class="mr-lg-3 mr-2"><Link to='/servicios'>Servicios</Link></li> {/* nueva linea */}
							<li class="mr-lg-3 mr-2"><Link to='/padres'>Padres</Link></li> {/* nueva linea */}
							<li class="mr-lg-3 mr-2"><Link to='/docentes'>Docentes</Link></li> {/* nueva linea */}
							
							
							<li class="mr-lg-3 mr-2"><Link to='/promo'>Egresados</Link></li>
							<li class="mr-lg-3 mr-2"><Link to='/contact'>Contactos</Link></li>
							

						</ul>
				</nav>
			</div>

		</div>
	</div>
</header>
        )
    }
}
export default Navbar