import React,{Component} from 'react';
 class Altamira extends Component{
     render(){
         return(
            <div>
            <section class="inner-page-banner" id="home">
            </section>
            
            
            
            <div class="breadcrumb-agile">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                        <a href="index.html">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Vereda Altamira</li>
                </ol>
            </div>
            
            
            <section class="what-we-do py-5">
                <div class="container py-md-5">
                <h3 class="heading text-center mb-3 mb-sm-5">Escuela Altamira Quichaya</h3>
                    <div class="row what-we-do-grid">
                        <div class="col-lg-3 col-md-6 pr-0 pl-md-3 pl-0">
                            <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                        </div>
                        <div class="col-lg-3 col-md-6 bg-grid-clr">
                            <h4 class="mt-md-0 my-2">titulo uno</h4>
                            <p class="">Sed ut perspiciatis undert sed omnis natus error .</p>
                        </div>
                        <div class="col-lg-3 col-md-6 pr-0 pl-md-3 pl-0 mt-lg-0 mt-4">
                            <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                        </div>
                        <div class="col-lg-3 col-md-6 bg-grid-clr mt-lg-0 mt-md-4">
                            <h4 class="mt-md-0 my-2">titulo dos</h4>
                            <p class="">Sed ut perspiciatis undert sed omnis natus error .</p>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 pr-0 pl-md-3 pl-0 mt-lg-5 mt-4">
                            <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                        </div>
                        <div class="col-lg-3 col-md-6 bg-grid-clr mt-lg-5 mt-md-4">
                            <h4 class="mt-md-0 my-2">titulo tres</h4>
                            <p class="">Sed ut perspiciatis undert sed omnis natus error .</p>
                        </div>
                        <div class="col-lg-3 col-md-6 pr-0 pl-md-3 pl-0 mt-lg-5 mt-4">
                            <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                        </div>
                        <div class="col-lg-3 col-md-6 bg-grid-clr mt-lg-5 mt-md-4">
                            <h4 class="mt-md-0 my-2">titulo cuatro </h4>
                            <p class="">Sed ut perspiciatis undert sed omnis natus error .</p>
                        </div>
                    </div>
                </div>
            </section>
            
                <section class="testimonials py-5" id="testimonials">
                    <div class="container py-md-5">
                           <h3 class="heading text-center mb-3 mb-sm-5">titulo segunda parte</h3>
                        <div class="row mt-3">
            
                            <div class="col-md-4 test-grid text-left px-lg-3">
                                <div class="test-info">
            
                                    <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                                    <h3 class="mt-md-4 mt-3"> Abraham Smith</h3>
            
                                    <div class="test-img text-center mb-3">
                                        <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                                    </div>
                                    <div class="mobl-footer test-soc text-center">
                                        <ul class="list-unstyled">
                                            <li>
                                                <a href="https://www.facebook.com/inseduc.quichaya.9">
                                        <span class="fa fa-facebook-f"></span>
                                    </a>
                                            </li>
                                            <li class="mx-1">
                                                <a href="#">
                                        <span class="fa fa-twitter"></span>
                                    </a>
                                            </li>
            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 test-grid text-left px-lg-3 py-sm-5 py-md-0 py-3">
                                <div class="test-info">
            
                                    <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                                    <h3 class="mt-md-4 mt-3"> Mariana Noe</h3>
                                    <div class="test-img text-center mb-3">
                                        <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                                    </div>
                                    <div class="mobl-footer test-soc text-center">
                                        <ul class="list-unstyled">
                                            <li>
                                                <a href="https://www.facebook.com/inseduc.quichaya.9">
                                        <span class="fa fa-facebook-f"></span>
                                    </a>
                                            </li>
                                            <li class="mx-1">
                                                <a href="#">
                                        <span class="fa fa-twitter"></span>
                                    </a>
                                            </li>
            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 test-grid text-left px-lg-3">
                                <div class="test-info">
            
                                    <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                                    <h3 class="mt-md-4 mt-3">Nebula Nairobi</h3>
            
                                    <div class="test-img text-center mb-3">
                                        <a href="#gal2"><img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" /></a>
                                    </div>
                                    <div class="mobl-footer test-soc text-center">
                                        <ul class="list-unstyled">
                                            <li>
                                                <a href="https://www.facebook.com/inseduc.quichaya.9">
                                        <span class="fa fa-facebook-f"></span>
                                    </a>
                                            </li>
                                            <li class="mx-1">
                                                <a href="#">
                                        <span class="fa fa-twitter"></span>
                                    </a>
                                            </li>
            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
            
            
                    </div>
                </section>
                </div>
         )
     }
 }
 export default Altamira