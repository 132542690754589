import firebase from "firebase/app";

 const firebaseConfig = {
    apiKey: "AIzaSyCZ9Krxww0sp70-kQuoUICIOyN2GRBUWXI",
    authDomain: "colegio-577d7.firebaseapp.com",
    databaseURL: "https://colegio-577d7.firebaseio.com",
    projectId: "colegio-577d7",
    storageBucket: "colegio-577d7.appspot.com",
    messagingSenderId: "660176365184",
    appId: "1:660176365184:web:dd769148e859a3061a2865",
    measurementId: "G-TBGY4YDD2F"
  };

  export default firebaseConfig;
