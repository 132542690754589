import React from 'react';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'; 
import Inicio from './components/Inicio';
import Institucional from './components/Quienes_somos';
import Estudiantes from './components/Estudiantes';
import Servicios from './components/Servicios';
import Padres from './components/Padres';
import Docentes from './components/Docetes';
import Altamira from './components/Altamira';
import Cabuyal from './components/Cabuyal';
import Palmar from './components/Palmar';
import Dantas from './components/Dantas';
import Golondrinas from './components/Golondrinas';
import Chuluambo from './components/Chuluambo';
import Promociones from './components/Promociones';
import Contact from './components/Contacto_cole';
import Simbolos from './components/Simbolos';


import Navbar from './components/partial/Navbar';
import Footer from './components/partial/Footer';


function App() {
  return (
    <div>
      
      <Router>
        <Navbar/>
          <Switch>
            {/* link munu  */}
            <Route exact path='/'component={Inicio}/>
            <Route path='/institucional' component={Institucional}/>  
            <Route path='/estudiantes' component={Estudiantes}/>
            <Route path='/servicios' component={Servicios}/>
            <Route path='/padres' component={Padres}/>
            <Route path='/docentes' component={Docentes}/>

              {/* //link submenu para las institucional*/}
              <Route path='/simbolos' component={Simbolos}/>

              {/* //link submenu para las sedes*/}
            <Route path='/altamira' component={Altamira}/>
            <Route path='/cabuyal' component={Cabuyal}/>
            <Route path='/palmar' component={Palmar}/>
            <Route path='/dantas' component={Dantas}/>
            <Route path='/golondrinas' component={Golondrinas}/>
            <Route path='/chuluambo' component={Chuluambo}/>

            
            <Route path='/promo' component={Promociones}/>
            <Route path='/contact' component={Contact}/>
          </Switch>
        <Footer/>
      </Router>
      
    </div>
  );
}

export default App;
