import React,{Component} from 'react';
import {Link} from 'react-router-dom';
//import firebase from "./Firebase";
import firebase from "firebase";

class Inicio extends  Component{
	db=firebase.firestore()
	state={
		seccion1:{},
		seccion2:{},
		seccion3:{},
		seccion4:{},
		seccion5:{},
		seccion6:{},
		portada:{}

	};
	componentDidMount(){
		this.db.collection("pagina").doc("inicio").get().then(data=>{
			if(data.data()){
				this.setState({seccion1:data.data().seccion1})
			}
			if(data.data()){
				this.setState({seccion2:data.data().seccion2})
			}
			if(data.data()){
				this.setState({seccion3:data.data().seccion3})
			}
			if(data.data()){
				this.setState({seccion4:data.data().seccion4})
			}
			if(data.data()){
				this.setState({seccion5:data.data().seccion5})
			}
			if(data.data()){
				this.setState({seccion6:data.data().seccion6})
			}
			if(data.data()){
				this.setState({portada:data.data().portada})
			}
		})
	}

    render(){
        return(
            <div>
                
 {/* <!-- //header -->
// <!-- fotos de inicio --> */}


{/* prueba fotos */}
<div className="slidernew">
	<ul>
		<li><img src={this.state.portada?this.state.portada.imagen1:""} alt="news image" class="img-fluid"/></li>
		<li><img src={this.state.portada?this.state.portada.imagen2:""} alt="news image" class="img-fluid"/></li>
		<li><img src={this.state.portada?this.state.portada.imagen3:""} alt="news image" class="img-fluid"/></li>
		<li><img src={this.state.portada?this.state.portada.imagen4:""} alt="news image" class="img-fluid"/></li>
	</ul>
</div>



 {/* <!-- //primer noticia  -->
//  <!-- banner bottom grids --> */}
    <section class="content-info py-5" id="about">
        <div class="container py-md-5">
			<h3 class="heading text-center mb-3 mb-sm-5">
			{this.state.seccion1?this.state.seccion1.titulo:""}
				</h3>

            <div class="info-w3pvt-mid text-center px-lg-5">

                <div class="title-desc text-center px-lg-5">
					<img src={this.state.seccion1?this.state.seccion1.imagen:""} alt="news image" class="img-fluid" />

                    <p class="px-lg-5">

						{this.state.seccion1?this.state.seccion1.contenido:""}
						
						{/* {console.log(this.state.seccion1)} */}

						</p>
                    
					<Link to="/services" class="btn mt-lg-4 mt-3 read scroll" role="button">Información local</Link>
                </div>
            </div>
        </div>
    </section>





     {/* <!-- segunda noticia  -->

//  <!-- /services --> */}
    <section class="services py-5" id="services">
        <div class="container py-md-5">
		  <h3 class="heading text-center mb-3 mb-sm-5">
		  {this.state.seccion2?this.state.seccion2.titulo:""}		  
		  </h3>
            <div class="row ab-info">
                <div class="col-md-6 ab-content ab-content1">
                    <div class="ab-content-inner">
                        <Link to="/single"><img src={this.state.seccion2?this.state.seccion2.imagen1:""} alt="news image" class="img-fluid" /></Link>						
						<div class="ab-info-con">
                            {/* <h4> Trim your Hair</h4> */}
							<p>
							{this.state.seccion2?this.state.seccion2.contenido1:""}
							</p>
							<Link to="/single" class="read-more two btn m-0 px-3" role="button"><span class="fa fa-arrow-circle-o-right"> </span></Link>
                            
                        </div>
                    </div>
                </div>
			

                <div class="col-md-6 ab-content ab-content1">
                    <div class="ab-content-inner">
					<Link to="/single"><img src={this.state.seccion2?this.state.seccion2.imagen2:""} alt="news image" class="img-fluid" /></Link>
                        <div class="ab-info-con">
                            {/* <h4>Trim your Beard</h4> */}
							<p>
							{this.state.seccion2?this.state.seccion2.contenido2:""}
							</p>


                            <a href="single.html" class="read-more two btn m-0 px-3"><span class="fa fa-arrow-circle-o-right"> </span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ab-info second mt-lg-4">
                <div class="col-md-3 ab-content">
                    <div class="ab-content-inner">
						<h3>{this.state.seccion3?this.state.seccion3.titulo:""}	</h3>
					<Link to="/single"><img src={this.state.seccion3?this.state.seccion3.imagen:""} alt="news image" class="img-fluid" /></Link>
                        <div class="ab-info-con">
                            {/* <h4>colouring</h4> */}
							<p>
							{this.state.seccion3?this.state.seccion3.contenido:""}
							</p>

                            <a href="single.html" class="read-more two btn m-0 px-3"><span class="fa fa-arrow-circle-o-right"> </span></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 ab-content">
                    <div class="ab-content-inner">
					<h3>{this.state.seccion4?this.state.seccion4.titulo:""}	</h3>
					<Link to="/single"><img src={this.state.seccion4?this.state.seccion4.imagen:""} alt="news image" class="img-fluid" /></Link>
                        <div class="ab-info-con">
                            {/* <h4>Bathing</h4> */}
							<p>
							{this.state.seccion4?this.state.seccion4.contenido:""}
							</p>
                            <a href="single.html" class="read-more two btn m-0 px-3"><span class="fa fa-arrow-circle-o-right"> </span></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 ab-content">
                    <div class="ab-content-inner">
					<h3>{this.state.seccion5?this.state.seccion5.titulo:""}	</h3>
					<Link to="/single"><img src={this.state.seccion5?this.state.seccion5.imagen:""} alt="news image" class="img-fluid" /></Link>
                        <div class="ab-info-con">
                            {/* <h4>drying</h4> */}
							<p>
							{this.state.seccion5?this.state.seccion5.contenido:""}
							</p>
                            <a href="single.html" class="read-more two btn m-0 px-3"><span class="fa fa-arrow-circle-o-right"> </span></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 ab-content">
                    <div class="ab-content-inner">
					<h3>{this.state.seccion6?this.state.seccion6.titulo:""}	</h3>
					<Link to="/single"><img src={this.state.seccion6?this.state.seccion6.imagen:""} alt="news image" class="img-fluid" /></Link>
                        <div class="ab-info-con">
                            {/* <h4>Creams</h4> */}
							<p>
							{this.state.seccion6?this.state.seccion6.contenido:""}
							</p>
                            <a href="single.html" class="read-more two btn m-0 px-3"><span class="fa fa-arrow-circle-o-right"> </span></a>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
    {/* // <!-- /services -->
 
	// <!-- pricing -->  */}
<section class="pricing py-5">	
	<div class="container py-md-5">
		<h3 class="heading text-capitalize text-center mb-3 mb-sm-5"> Nuestras sedes</h3>
		<div class="row pricing-grids">
			<div class="col-lg-6  mb-lg-0 mb-5">
				<div class="padding">
					 <h3>Sedes de la institución Educativa Quichaya</h3>
					{/* <!-- Item starts --> */}
					{/* <div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Sedes</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda</h6>
							</div>
						</div>
						
					</div> */}
					
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Quichaya</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Quichaya-Centro</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Escuela Altamira Quichaya</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Altamira</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item my-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Cabuyal</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Cabuyal</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Palmar</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Palmar</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Dantas</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Dantas</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Golondrinas</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Golondrinas</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Experimental Chuluambo</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Chuluambo</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends --> */}
				</div>
			</div>

			{/* OTRO MENU en nasa */}			

			<div class="col-lg-6  mb-lg-0 mb-5">
				<div class="padding">
					 <h3>Sedes de la institución Educativa Quichaya</h3>
					{/* <!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Centro Docente Rural Mixto Quichaya</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Quichaya-Centro</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat tuulu thãj Altamira</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Altamira</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item my-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat kwet thkaafx  thãj Cabuyal</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Cabuyal</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat çxi'nxu thãj el Palmar</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Palmar</h6>
							</div>
						</div>
						
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat jiba khuçx thãj Dantas</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Dantas</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat  dxuus vxçxakwe thãj Golondrinas</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Golondrinas</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends -->
					<!-- Item starts --> */}
					<div class="menu-item mt-4">
						<div class="row border-dot no-gutters">
							<div class="col-8 menu-item-name">
								<h6>Piyanxi' yat çxlabu Chuluambo</h6>
							</div>
							<div class="col-4 menu-item-price text-right">
								<h6>Vereda Chuluambo</h6>
							</div>
						</div>
					</div>
					{/* <!-- Item ends --> */}
				</div>
			</div>

			{/* FIN OTRO MENU nasa */}	
		</div>		
	</div>		
</section>
// {/* <!-- //pricing -->
//   <!--/order-now--> */}
  {/*   <section class="order-sec py-5">
        <div class="container py-md-5">
            <div class="test-info text-center">
                <h3 class="tittle order">
                    <span>CALL US TO BOOK An APPOINTMENT</span>Our team will call back immediately and Book the Appointment</h3>
                <h4 class="tittle my-2">123456789  </h4>

                <div class="read-more mx-auto m-0 text-center">
					<Link to="/contact" class="read-more scroll btn">Click here</Link> 
				</div>
            </div>
        </div>
    </section> */}
     {/* <!--//order-now-->

//  <!--/testimonials--> */}

    {/* <section class="testimonials py-5" id="testimonials">
        <div class="container py-md-5">
               <h3 class="heading text-center mb-3 mb-sm-5">Client Reviews</h3>
            <div class="row mt-3">

                <div class="col-md-4 test-grid text-left px-lg-3">
                    <div class="test-info">

                        <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                        <h3 class="mt-md-4 mt-3"> Abraham Smith</h3>

                        <div class="test-img text-center mb-3">
                            <img src="assets/images/test1.jpg" class="img-fluid" alt="user-image" />
                        </div>
                        <div class="mobl-footer test-soc text-center">
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#">
							<span class="fa fa-facebook-f"></span>
						</a>
                                </li>
                                <li class="mx-1">
                                    <a href="#">
							<span class="fa fa-twitter"></span>
						</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 test-grid text-left px-lg-3 py-sm-5 py-md-0 py-3">
                    <div class="test-info">

                        <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                        <h3 class="mt-md-4 mt-3"> Mariana Noe</h3>
                        <div class="test-img text-center mb-3">
                            <img src="assets/images/test2.jpg" class="img-fluid" alt="user-image" />
                        </div>
                        <div class="mobl-footer test-soc text-center">
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#">
							<span class="fa fa-facebook-f"></span>
						</a>
                                </li>
                                <li class="mx-1">
                                    <a href="#">
							<span class="fa fa-twitter"></span>
						</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 test-grid text-left px-lg-3">
                    <div class="test-info">

                        <p>Lorem Ipsum has been the industry's standard since the 1500s. Praesent ullamcorper dui turpis.</p>
                        <h3 class="mt-md-4 mt-3">Nebula Nairobi</h3>

                        <div class="test-img text-center mb-3">
                            <img src="assets/images/test3.jpg" class="img-fluid" alt="user-image" />
                        </div>
                        <div class="mobl-footer test-soc text-center">
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#">
							<span class="fa fa-facebook-f"></span>
						</a>
                                </li>
                                <li class="mx-1">
                                    <a href="#">
							<span class="fa fa-twitter"></span>
						</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section> */}

 {/* <!--//testimonials-->
// <!-- subscribe --> */}
{/* <section class="subscribe" id="subscribe">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-5 d-flex subscribe-left p-lg-5 py-sm-5 py-4">
				<div class="news-icon mr-3">
					<span class="fa fa-paper-plane" aria-hidden="true"></span>
				</div>
				<div class="text">
					<h3>Subscribe To Our Newsletter</h3>
				</div>
			</div>
			<div class="col-md-7 subscribe-right p-lg-5 py-sm-5 py-4">
				<form action="#" method="post">
					<input type="email" name="email" placeholder="Enter your email here" required="" />
					<button class="btn1"><span class="fa fa-paper-plane" aria-hidden="true"></span></button>
				</form>
				<p>we never share your email with anyone else</p>
			</div>
		</div>
	</div>
</section> */}
 {/* <!-- //subscribe -->
// <!-- footer --> */}
    
    </div>
        )
    }
}
export default Inicio