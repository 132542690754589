import React, {Component} from 'react';

class Footer extends Component{
    render(){
        return(
            <footer class="footer-content">
        <div class="layer footer">
            <div class="container-fluid">
                <div class="row footer-top-inner-w3ls">
                    <div class="col-lg-4 col-md-6 footer-top ">
                        <h2>
                            <a href="index.html">iNFORMACIÓN</a>
                        </h2>
                        <p class="my-3"> Resguardo Indigena de Quichaya </p>
                        <p>
                            Nuestra Institucion esta localizada al oriente del cauca  
                         </p>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
                        <div class="footer-w3pvt">
                            <h3 class="mb-3 w3pvt_title">Horario de trabajo</h3>
                            <hr/>
                            <ul class="list-info-w3pvt last-w3ls-contact mt-lg-4">
                                <li>
                                    <p> Lunes - Viernes 08.00 am - 05.00 pm</p>

                                </li>
                                
                                {/* <li class="my-2">
                                    <p>Sunday 08.00 am - 10.00 pm</p>

                                </li> */}


                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mt-lg-0 mt-5">
                        <div class="footer-w3pvt">
                            <h3 class="mb-3 w3pvt_title">Contactanos</h3>
                            <hr/>
                            <div class="last-w3ls-contact">
                                <p>
                                    <a href="mailto:example@email.com">iequichaya@hotmail.com</a>
                                </p>
                            </div>
                            <div class="last-w3ls-contact my-2">
                                <p>+57 3116252211</p>
                            </div>
                            <div class="last-w3ls-contact">
                                <p>DANE: 219743000147,
                                    <br/>NIT: 000000000.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <p class="copy-right-grids text-li text-center my-sm-4 my-4">© 2020 Institución Educativa Quichaya
                    {/* <a href="http://w3layouts.com/"> Reserved </a> */}
                </p>
                <div class="w3ls-footer text-center mt-4">
                    <ul class="list-unstyled w3ls-icons">
                        <li>
                            <a href="https://www.facebook.com/inseduc.quichaya.9">
							<span class="fa fa-facebook-f"></span>
						</a>
                        </li>
                        <li>
                            <a href="#">
							<span class="fa fa-twitter"></span>
						</a>
                        </li>
                        <li>
                            <a href="#">
							<span class="fa fa-dribbble"></span>
						</a>
                        </li>
                        <li>
                            <a href="#">
							<span class="fa fa-vk"></span>
						</a>
                        </li>
                    </ul>
                </div>
                <div class="move-top text-right"><a href="#home" class="move-top"> <span class="fa fa-angle-up  mb-3" aria-hidden="true"></span></a></div>
            </div>
            {/* <!-- //footer bottom --> */}
        </div>
    </footer>
        )
    }
}
export default Footer