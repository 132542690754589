import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import firebase from "firebase";

class Estudiantes extends Component{
    db=firebase.firestore()
	state={
        seccion1:{},
        seccion2:{}
	};
	componentDidMount(){
		this.db.collection("pagina").doc("estudiante").get().then(data=>{
			if(data.data()){
				this.setState({seccion1:data.data().seccion1})
			}
			if(data.data()){
				this.setState({seccion2:data.data().seccion2})
			}
		})
	}
    
    render(){
        return(
            <div>
                {/* <!-- banner --> */}
<section class="inner-page-banner" id="home">
</section>
{/* <!-- //banner -->
<!-- page details --> */}
<div class="breadcrumb-agile">
	<ol class="breadcrumb mb-0">
		<li class="breadcrumb-item">
			<Link to='/'>Estudiantes</Link>
		</li>
		{/* <li class="breadcrumb-item active" aria-current="page">About Us</li> */}
	</ol>
</div>
{/* <!-- //page details -->
	<!--about-mid --> */}
    <section class="banner-bottom py-5" id="exp">
        <div class="container py-md-5">
	<h3 class="heading text-center mb-3 mb-sm-5">{this.state.seccion1?this.state.seccion1.titulo:""} </h3>
            <div class="row mid-grids mt-lg-5 mt-3">
                <div class="col-md-5 content-w3pvt-img">
                    <img src={this.state.seccion1?this.state.seccion1.imagen1:""} alt="news image" class="img-fluid" />
                </div>
                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    {/* <h5 class="mt-1">CLASSIC HAIR STYLING </h5> */}
                    <h4>{this.state.seccion1?this.state.seccion1.subtitulo1:""}</h4>

                    <p class="mt-2 text-left">
                    {this.state.seccion1?this.state.seccion1.contenido1:""}
                    </p>

                </div>

            </div>

            

            <div class="row mid-grids mt-lg-5 mt-3 py-3">

                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    {/* <h5 class="mt-1">CLASSIC HAIR STYLING </h5> */}
                    <h4>{this.state.seccion1?this.state.seccion1.subtitulo2:""}</h4>

                    <p class="mt-2 text-left">
                    {this.state.seccion1?this.state.seccion1.contenido2:""} 
                    </p>

                </div>
                <div class="col-md-5 content-w3pvt-img mt-lg-0 mt-3">
                <img src={this.state.seccion1?this.state.seccion1.imagen2:""} alt="news image" class="img-fluid" />
                </div>
            </div>

            <div class="row mid-grids mt-lg-5 mt-3">
                <div class="col-md-5 content-w3pvt-img">
                <img src={this.state.seccion1?this.state.seccion1.imagen3:""} alt="news image" class="img-fluid" />
                </div>
                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    {/* <h5 class="mt-1">CLASSIC HAIR STYLING </h5> */}
                    <h4>{this.state.seccion1?this.state.seccion1.subtitulo3:""}</h4>

                    <p class="mt-2 text-left">
                    {this.state.seccion1?this.state.seccion1.contenido3:""} 
                    </p>

                </div>

            </div>

            <div class="row mid-grids mt-lg-5 mt-3 py-3">

                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    {/* <h5 class="mt-1">CLASSIC HAIR STYLING </h5> */}
                    <h4>{this.state.seccion1?this.state.seccion1.subtitulo4:""}</h4>

                    <p class="mt-2 text-left">
                    {this.state.seccion1?this.state.seccion1.contenido4:""} 
                    </p>

                </div>
                <div class="col-md-5 content-w3pvt-img mt-lg-0 mt-3">
                <img src={this.state.seccion1?this.state.seccion1.imagen4:""} alt="news image" class="img-fluid" />
                </div>
            </div>

            


        </div>
    </section>
    {/* <!-- //about-mid -->
<!-- states --> */}
  <section class="stats-count">
  <div class="overlay py-5">
    <div class="container py-md-5">
      <div class="row text-center">
        
      </div>
    </div>
	</div>
  </section>
  {/* <!--//states -->

 <!--//team parte 2 --> */}
    <section class="banner-bottom  py-5">
        <div class="container py-md-5">
			<h3 class="heading text-center mb-3 mb-sm-5">{this.state.seccion2?this.state.seccion2.titulo:""}</h3>
            <div class="row mt-lg-5 mt-4">
                <div class="col-md-4 team-gd text-center">
                    <div class="team-img mb-4">
                    <img src={this.state.seccion2?this.state.seccion2.imagen1:""} alt="news image" class="img-fluid" />
                    </div>
                    <div class="team-info">
                        <h3 class="mt-md-4 mt-3">{this.state.seccion2?this.state.seccion2.subtitulo1:""}</h3>
                        <p>{this.state.seccion2?this.state.seccion2.contenido1:""} </p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="col-md-4 team-gd second text-center my-md-0 my-5">
                    <div class="team-img mb-4">
                    <img src={this.state.seccion2?this.state.seccion2.imagen2:""} alt="news image" class="img-fluid" />
                    </div>
                    <div class="team-info">
                    <h3 class="mt-md-4 mt-3">{this.state.seccion2?this.state.seccion2.subtitulo2:""}</h3>
                        <p>{this.state.seccion2?this.state.seccion2.contenido2:""} </p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-4 team-gd text-center">
                    <div class="team-img mb-4">
                    <img src={this.state.seccion2?this.state.seccion2.imagen3:""} alt="news image" class="img-fluid" />
                    </div>
                    <div class="team-info">
                    <h3 class="mt-md-4 mt-3">{this.state.seccion2?this.state.seccion2.subtitulo3:""}</h3>
                        <p>{this.state.seccion2?this.state.seccion2.contenido3:""} </p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </section>

            </div>
	
        )
    }
}
export default Estudiantes