import React, {Component} from 'react';

import {Link} from 'react-router-dom';

class Institucional extends Component{
    render(){
        return(
            <div>
                {/* <!-- banner --> */}
<section class="inner-page-banner" id="home">
</section>
{/* <!-- //banner -->
<!-- page details --> */}
<div class="breadcrumb-agile">
	<ol class="breadcrumb mb-0">
		<li class="breadcrumb-item">
			<Link to='/'>Institucional</Link>
		</li>
		{/* <li class="breadcrumb-item active" aria-current="page">About Us</li> */}
	</ol>
</div>
{/* <!-- //page details -->
	<!--about-mid --> */}
    <section class="banner-bottom py-5" id="exp">
        <div class="container py-md-5">
	<h3 class="heading text-center mb-3 mb-sm-5">Quienes somos</h3>
            <div class="row mid-grids mt-lg-5 mt-3">
                <div class="col-md-5 content-w3pvt-img">
                    <img src="assets/images/escudocole.jpeg" alt="" class="img-fluid" />
                </div>
                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    <h1 class="mt-1">RESEÑA HISTORICA</h1>
                    {/* <h4>real men go to
                        <br/>real Men spas</h4> */}
                    <p class="mt-2 text-left"> poner todo con respecto a la RESEÑA HISTORICA</p>

                </div>
            </div>

            <div class="row mid-grids mt-lg-5 mt-3 py-3">
                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                     <h1 class="mt-1">MISION </h1>
                    {/* <h4>checkout hottest 
                        <br />beard styling</h4> */}
                    <p class="mt-2 text-left">poner todo con respecto a la MISION</p>

                </div>
                <div class="col-md-5 content-w3pvt-img mt-lg-0 mt-3">
                    <img src="assets/images/escudocole.jpeg" alt="" class="img-fluid" />
                </div>
            </div>

            <div class="row mid-grids mt-lg-5 mt-3">
                <div class="col-md-5 content-w3pvt-img">
                    <img src="assets/images/escudocole.jpeg" alt="" class="img-fluid" />
                </div>
                <div class="col-md-7 content-left-bottom entry-w3ls-info text-left mt-3">
                    <h1 class="mt-1">VISION </h1>
                    {/* <h4>real men go to
                        <br/>real Men spas</h4> */}
                    <p class="mt-2 text-left"> poner todo con respecto a la VISION</p>

                </div>
            </div>



        </div>
    </section>
    {/* <!-- //about-mid -->
 <!-- states --> */}
  <section class="stats-count">
  <div class="overlay py-5">
    <div class="container py-md-5">
      <div class="row text-center">
        
      </div>
    </div>
	</div>
  </section>
  {/* <!--//states -->

 <!--//team --> */}
    <section class="banner-bottom  py-5">
        <div class="container py-md-5">
			<h3 class="heading text-center mb-3 mb-sm-5">mas sobre Institucional</h3>
            <div class="row mt-lg-5 mt-4">
                <div class="col-md-4 team-gd text-center">
                    <div class="team-img mb-4">
                        <img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" />
                    </div>
                    <div class="team-info">
                        <h3 class="mt-md-4 mt-3">Institucional 1</h3>
                        <p>Lorem Ipsum has been the industry's standard since the 1500s.</p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>

                <div class="col-md-4 team-gd second text-center my-md-0 my-5">
                    <div class="team-img mb-4">
                    <img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" />
                    </div>
                    <div class="team-info">
                        <h3 class="mt-md-4 mt-3">Institucional dos</h3>
                        <p>Lorem Ipsum has been the industry's standard since the 1500s.</p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-4 team-gd text-center">
                    <div class="team-img mb-4">
                    <img src="assets/images/recreEstu.jpg" class="img-fluid" alt="user-image" />
                    </div>
                    <div class="team-info">
                        <h3 class="mt-md-4 mt-3"> Institucional tres</h3>
                        <p>Lorem Ipsum has been the industry's standard since the 1500s.</p>
                        <ul class="list-unstyled team-icons mt-4">
                            <li>
                                <a href="https://www.facebook.com/inseduc.quichaya.9" class="t-icon">
                                    <span class="fa fa-facebook-f"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="t-icon">
                                    <span class="fa fa-dribbble"></span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </section>

            </div>
	
        )
    }
}
export default Institucional